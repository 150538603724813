<template>
  <div class="Product-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <v-container fluid>
          <table class="center">
            <tbody class="report-container">
              <v-card-text>
                <v-data-table dense readonly :sort-by="sortBy" sort-desc :headers="headers"
                  :itemsPerPage="Product.length" hide-default-footer :expanded.sync="expanded" show-expand
                  :items="Product">
                  <template v-slot:item="{ item, isExpanded, expand }">
                    <tr v-if="item.totalPrice - item.totalCost !== 0">
                      <td class="text-left">
                        <v-btn class="no-print" @click="expand(true)" icon v-if="!isExpanded">
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                        <v-btn class="no-print" @click="expand(false)" icon v-if="isExpanded">
                          <v-icon>mdi-chevron-up</v-icon>
                        </v-btn>
                      </td>
                      <td class="text-left text-capitalize">
                        <span v-if="item.trackSerialNumber">
                          <v-icon color="blue" small>mdi-barcode-scan</v-icon>
                        </span>
                        <span v-else>
                          <v-icon color="teal" small>mdi-package-variant-closed</v-icon>
                        </span>
                        <span class="ml-2">{{ item.name }}</span>
                      </td>
                      <td class="text-center">
                        {{ item.totalQty }} - <span class="caption pink--text">{{ (item.totalQty / totalQty *
                            100).toFixed(2)
                        }}%</span>
                      </td>
                      <td class="text-right">
                        {{ item.totalPrice | currency }}
                      </td>
                      <td v-if="!hideCost" class="text-right">
                        {{ item.totalCost | currency }}
                      </td>
                      <td v-if="!hideProfit" class="text-right" :style="{ color: color(item) }">
                        {{ (item.totalPrice - item.totalCost) | currency }} / <span class="green--text">{{
                            ((item.totalPrice -
                              item.totalCost) /
                              item.totalCost * 100).toFixed(2)
                        }}%</span>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-0">
                      <v-card color="#F3F8FC" flat>
                        <v-card-text class="title">
                          <InvoiceDetail :ProductId="item.id" :CustomerId="CustomerId" :start="start" :end="end"
                            :hideProfit="hideProfit" :hideCost="hideCost"></InvoiceDetail>
                        </v-card-text>
                      </v-card>
                    </td>
                  </template>
                  <template v-slot:body.append v-if="Product.length > 0">
                    <tr :style="{ 'background-color': $style.listTotal.filter }">
                      <td></td>

                      <td class="font-weight-bold">Total</td>
                      <td class="text-right font-weight-bold text-center">{{ totalQty }} - <span
                          class="caption pink--text">100%</span></td>
                      <td class="text-right font-weight-bold">
                        {{ totalPrice | currency }}
                      </td>
                      <td v-if="!hideCost" class="text-right font-weight-bold">
                        {{ totalCost | currency }}
                      </td>
                      <td v-if="!hideProfit" class="text-right font-weight-bold">
                        {{ totalProfit | currency }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import saleService from "@/modules/ReportView/service.js";
const InvoiceDetail = () =>
  import("@/modules/ReportView/GlobalReport/InvoiceDetail.vue");

export default {
  props: [
    "CategoryId",
    "reset",
    "CustomerId",
    "hideProfit",
    "hideCost",
    "start",
    "end",
  ],
  data() {
    return {
      isLoading: true,
      Product: [],
      expanded: [],
      expenseExpanded: [],
      sortBy: "totalQty",
      headers: [
        {
          text: "NAME",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "QTY",
          align: "center",
          value: "totalQty",
          sortable: true,
        },
        {
          text: "SALE",
          align: "right",
          value: "totalPrice",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    totalProfit() {
      return this.totalPrice - this.totalCost;
    },
    totalPrice() {
      let total = 0;
      this.Product.map((row) => {
        total += row.totalPrice;
      });
      return total;
    },
    totalCost() {
      let total = 0;
      this.Product.map((row) => {
        total += row.totalCost;
      });
      return total;
    },
    totalQty() {
      let total = 0;
      this.Product.map((row) => {
        if (row.totalQty) {
          total += parseInt(row.totalQty);
        }
      });
      return total;
    },
  },
  watch: {
    reset() {
      // console.log('i am reset got call', this.start, this.end)
      this.getProduct();
    },
    CustomerId() {
      this.getProduct();
    },
  },
  components: {
    InvoiceDetail,
  },
  async created() {
    // this.start = this.$route.query.start;
    // this.end = this.$route.query.end;
    await this.getProduct();
    if (!this.hideCost) {
      this.headers.push({
        text: "COST",
        align: "right",
        value: "totalCost",
        sortable: true,
      });
    }
    if (!this.hideProfit) {
      this.headers.push({
        text: "PROFIT",
        align: "right",
        value: "totalProfit",
        sortable: true,
      });
    }
  },
  methods: {
    color(item) {
      let value = "black";
      let profit = item.totalPrice - item.totalCost;
      if (profit < 0) {
        value = "red";
      }
      return value;
    },
    getProduct() {
      this.isLoading = true;
      let where = {};
      // console.log('start', this.start)
      // console.log('end', this.end)
      if (this.start) where.start = this.start;
      if (this.end) where.end = this.end;
      if (this.CustomerId) where.CustomerId = this.CustomerId;
      if (this.CategoryId) where.CategoryId = this.CategoryId;

      // console.log('call where', where)
      return saleService.getProduct(where).then((response) => {
        this.$Progress.finish();
        this.Product = response.data;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .no-print {
    display: none;
  }

  .detailBox {
    overflow: visible;
    height: 100%;
  }

  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}

.center {
  background-color: white;
  margin: auto;
  width: 100%;
}

/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
