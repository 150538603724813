var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Product-view"},[(_vm.isLoading)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-progress-linear',{attrs:{"indeterminate":true}})],1)],1):_c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('table',{staticClass:"center"},[_c('tbody',{staticClass:"report-container"},[_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","readonly":"","sort-by":_vm.sortBy,"sort-desc":"","headers":_vm.headers,"itemsPerPage":_vm.Product.length,"hide-default-footer":"","expanded":_vm.expanded,"show-expand":"","items":_vm.Product},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(item.totalPrice - item.totalCost !== 0)?_c('tr',[_c('td',{staticClass:"text-left"},[(!isExpanded)?_c('v-btn',{staticClass:"no-print",attrs:{"icon":""},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e(),(isExpanded)?_c('v-btn',{staticClass:"no-print",attrs:{"icon":""},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_vm._e()],1),_c('td',{staticClass:"text-left text-capitalize"},[(item.trackSerialNumber)?_c('span',[_c('v-icon',{attrs:{"color":"blue","small":""}},[_vm._v("mdi-barcode-scan")])],1):_c('span',[_c('v-icon',{attrs:{"color":"teal","small":""}},[_vm._v("mdi-package-variant-closed")])],1),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.name))])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.totalQty)+" - "),_c('span',{staticClass:"caption pink--text"},[_vm._v(_vm._s((item.totalQty / _vm.totalQty * 100).toFixed(2))+"%")])]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.totalPrice))+" ")]),(!_vm.hideCost)?_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.totalCost))+" ")]):_vm._e(),(!_vm.hideProfit)?_c('td',{staticClass:"text-right",style:({ color: _vm.color(item) })},[_vm._v(" "+_vm._s(_vm._f("currency")((item.totalPrice - item.totalCost)))+" / "),_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(((item.totalPrice - item.totalCost) / item.totalCost * 100).toFixed(2))+"%")])]):_vm._e()]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-card',{attrs:{"color":"#F3F8FC","flat":""}},[_c('v-card-text',{staticClass:"title"},[_c('InvoiceDetail',{attrs:{"ProductId":item.id,"CustomerId":_vm.CustomerId,"start":_vm.start,"end":_vm.end,"hideProfit":_vm.hideProfit,"hideCost":_vm.hideCost}})],1)],1)],1)]}},(_vm.Product.length > 0)?{key:"body.append",fn:function(){return [_c('tr',{style:({ 'background-color': _vm.$style.listTotal.filter })},[_c('td'),_c('td',{staticClass:"font-weight-bold"},[_vm._v("Total")]),_c('td',{staticClass:"text-right font-weight-bold text-center"},[_vm._v(_vm._s(_vm.totalQty)+" - "),_c('span',{staticClass:"caption pink--text"},[_vm._v("100%")])]),_c('td',{staticClass:"text-right font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalPrice))+" ")]),(!_vm.hideCost)?_c('td',{staticClass:"text-right font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalCost))+" ")]):_vm._e(),(!_vm.hideProfit)?_c('td',{staticClass:"text-right font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalProfit))+" ")]):_vm._e()])]},proxy:true}:null],null,true)})],1)],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }